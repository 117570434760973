import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../../../components/Layout'
import SEO from '../../../components/seo'

class gateAutomationPage extends React.Component {
  render() {
    const siteTitle =
      'Automatic Gate Openers & Operators - Arlington, TX and Dallas-Fort Worth, TX'
    const siteDescription =
      'Do you need an automatic gate opener? We install and repair gate access systems, sliding gate openers, and all gate operators. Contact us today!'

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />

        <div className="container-fluid text-center headline gates-headline">
          <div className="container">
            <h1 className="text-white m-0">Automatic Gate Openers & Repair</h1>
            <hr />
            <p className="text-white mx-auto">
              We have installed and repaired thousands of automatic gate
              operators in Arlington, TX and across the DFW metroplex.
              Experience has shown that in order for a property owner to get the
              most out of their gate, they must have a dependable, efficient
              gate automation system. For a reliable automated gate system, call
              J&J Gates Service and Design today at{' '}
              <a href="tel:+1-817-466-2794">(817) 466-2794</a>.
            </p>
          </div>
        </div>

        <div className="container d-padding-t d-padding-b set-width text-center mx-auto">
          <div className="d-padding-b">
            <h4 className="mt-0">Gate Access Systems</h4>
            <hr />
            <p>
              There are many different types of gate automation controls in use
              today. Some are very complex and require entry gate motors, and
              others are simple hinges. <Link to="/">Gate access systems</Link>{' '}
              are used to open gates automatically and have several different
              mechanisms involved. One of these is the motor that allows the
              gate to open and close on its own. The other part of the system is
              the device that triggers the motor. This could be a remote
              control, a button, or a voice activation system. Such devices
              allow for gates to be controlled from inside the home or business,
              to let a guest in, or from the car, to let yourself in without
              having to get out of the vehicle and do it manually.
            </p>
          </div>

          <div className="d-padding-b">
            <h4 className="mt-0">Sliding Gate Openers</h4>
            <hr />
            <p>
              <Link to="/">Sliding gate</Link> operators use tracks, which slide
              them open automatically. These can be tough for individuals to
              repair or install on their own because they have many moving parts
              such as chains, wires, wheels, and motors that must all work in
              conjunction in order for them to function properly. We are capable
              of repairing these sliding gate systems quickly, competently, and
              affordable.
            </p>
          </div>

          <div>
            <h4 className="mt-0">We Install & Repair All Gate Operators</h4>
            <hr />
            <p>
              We have years of experience with gates and the devices that
              automate them and can install them on new or existing gates which
              need to be modernized. We also have the ability to repair simple
              gate openers like hinges and tracks that are not as complex. Some
              of these devices are easier to replace, but for antique or custom
              gates, which do not have replacement parts available, they will
              need to be fixed by qualified professionals in order for them to
              function properly.
            </p>
            <p>
              No matter what type of gate access system you have or need, we
              have the expertise required to install, service, and repair it.
              Give us a call today and we will have your gate working again in
              short order. We are centrally located in{' '}
              <Link to="/">Arlington</Link> and service the entire{' '}
              <Link to="/">Dallas</Link> &{' '}
              <Link to="/">Fort Worth metroplex</Link>. Give us a call today for
              a free estimate <a href="tel:+1-817-466-2794">(817) 466-2794.</a>
            </p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default gateAutomationPage

export const gateAutomationPageQuery = graphql`
  query gateAutomationPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
